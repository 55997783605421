











































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Banner} from '@/model/resource/Banner'

@Component
export default class BannerCard extends Vue {
  @Prop({type: Banner, required: true}) banner!: Banner

  async handleBannerClick() {
    switch (this.banner.action) {
      case 'external-link':
        window.open(this.banner.url ?? '', '_blank')
        break
      case 'gleeder':
        await this.$router.push('/')
        break
      default:
        break
    }
  }
}
